.Header {
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.Header-combined {
  display: flex;
  flex-direction: column;
}

.Footer {
  background-color: #6200EE;
  display: flex;
  flex-direction: column;
}

.Footer-copyright {
  background-color: black;
  height: 1.5em;
  text-align: center;
}

.Copyright-text {
  color: white;
  font-size: 80%;
  font-weight: bold;
  font-family: 'Open Sans', sans-serif;
}

.Title-container {
  margin-left: 5%;
  margin-top: 1%;
  height: 5em;
  /* justify-self: center; */
  align-self: center;
  
}

.Title {
  color: white;
  font-size: 250%;
  font-family: 'Marmelad', sans-serif;
  font-weight: bold;
}

.Sub-title {
  color: white;
  font-size: 120%;
  font-family: 'Space Grotesk', sans-serif;
  font-weight: bold;
}

.Navbar {
  
  margin-right: 10%;
  margin-left: 1%;
  align-items: center;
  display: flex;
}

.Nav-link {
  font-family: 'Open Sans', sans-serif;
  font-size: 150%;
  font-weight: bold;
  text-align: center;
  margin-left: 4%;
  color: black;
  padding: 4px 20px;
  cursor: pointer;
  text-decoration: none;
}

.BackgroundImage {
  background-image: url("./images/background.png");
}

.Image {
  width: 25%;
  margin-left: 5%;
  margin-right: 5%;
}

.Image1-of-2 {
  width: 48%;
  margin-bottom: 10%;
}

.Image2-of-2 {
  width: 48%;
  margin-left: 3%;
}

.Texts {
  display: flex;
  flex-direction: column;
  margin-left: 5%;
  margin-right: 5%;
}

.Subscribe-text {
  color: black;
  font-size: 120%;
  font-family: 'Space Grotesk', sans-serif;
  font-weight: bold;
  width: 50%;
  margin-top: 5%;
  text-align: center;
  margin-bottom: 100px;
}

.address-text {
  color: black;
  font-size: 200%;
  font-family: 'Space Grotesk', sans-serif;
  font-weight: bold;
  width: 50%;
  margin-top: 5%;
  text-align: center;
  margin-bottom: 100px;
}

.Primary-text {
  color: white;
  font-size: 550%;
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
}

.Secondary-text {
  color: white;
  font-size: 300%;
  font-family: 'Open Sans', sans-serif;
  margin-top: 8%;
}

.Secondary-text-2 {
  color: white;
  font-size: 100%;
  font-family: 'Open Sans', sans-serif;
}

.Page {
  display: flex;
  flex-direction: column;
}

.Text-and-image {
  display: flex;
  flex-direction: row;
  text-align: center;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 5%;
}

.Subscribe {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  background-color: #F9F9F9;
}

.Input-and-button {
  display: flex;
  flex-direction: row;
  margin-top: 5%;
  margin-bottom: 5%;
  width: 50%;
  align-items: center;
  align-content: center;
}

.Text-input-background {
  background-color: #ECF0F9;
  border-radius: 35px;
  margin-right: 5%;
  height: 3em;
  width: 70%;
  padding-left: 5%;
}

.Text-input {
  background-color: #ECF0F9;
  border-color: #ECF0F9;
  border-style: none;
  border-radius: 35px;
  margin-top: 3%;
  width: 90%;
  height: 2em;
}

input:focus {
  outline: none;
}

input[type=submit]:hover {
  cursor: pointer;
}

.Sign-up-button {
  background-color: #FEBE3A;
  font-family: 'Space Grotesk', sans-serif;
  font-weight: bold;
  height: 3em;
  width: 30%;
  border-radius: 5px;
  margin-left: 5%;
}

.Form-and-message {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.Email-success-message {
  color: #6200EE;
  font-family: 'Space Grotesk', sans-serif;
  font-weight: bold;
  margin-bottom: 5%;
  text-align: center;
}

.hideMeAfter5Seconds {
  animation: hideAnimation 0s ease-in 5s;
  animation-fill-mode: forwards;
}

/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 80%; /* Could be more or less, depending on screen size */
}

/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.accessibility_button {
  background-color: #808080;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 20px;
  cursor: pointer;
}

p.p1 {
  margin: 0.0px 0.0px 10.0px 0.0px;
  text-align: center;
  font: 18.0px Arial;
  color: #000000
}

p.p2 {
  margin: 0.0px 0.0px 10.0px 0.0px;
  text-align: center;
  font: 12.0px Arial;
  color: #000000
}

p.p3 {
  margin: 0.0px 0.0px 7.5px 0.0px;
  text-align: justify;
  font: 13.0px 'Times New Roman';
  color: #262626
}

p.p4 {
  margin: 0.0px 0.0px 7.5px 0.0px;
  text-align: justify;
  font: 13.0px 'Times New Roman';
  color: #262626;
  background-color: #ffff0b;
  min-height: 16.0px
}

span.s1 {
  font: 12.0px Helvetica
}

span.s2 {
  font: 13.0px Helvetica
}

span.s3 {
  font: 13.0px Arial
}

span.s4 {
  font: 14.5px Verdana;
  letter-spacing: 0.3px;
  color: #191c1f;
  background-color: #ffffff
}

span.s5 {
  text-decoration: underline
}

span.s6 {
  font: 13.0px Helvetica;
  text-decoration: underline;
  color: #0b4cb4
}

@keyframes hideAnimation {
  to {
    visibility: hidden;
    width: 0;
    height: 0;
  }
}

@keyframes fadeInAnimation {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}

/* Shared styles for both desktop and mobile */
.Header {
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.Header-combined {
  display: flex;
  flex-direction: column;
}

.Footer {
  background-color: #6200EE;
  display: flex;
  flex-direction: column;
}

.Footer-copyright {
  background-color: black;
  height: 1.5em;
  text-align: center;
}

.Copyright-text {
  color: white;
  font-size: 80%;
  font-weight: bold;
  font-family: 'Open Sans', sans-serif;
}

/* Mobile Styles */
@media (max-width: 768px) {
  /* Adjust Header and Footer for mobile */
  .Header {
    flex-direction: column;
    align-items: center;
    padding: 10px;
  }

  .Footer {
    padding: 10px;
  }

  .Footer-copyright {
    height: auto;
  }

  /* Mobile adjustments for Title and Navbar */
  .Title-container {
    margin: 2% auto;
    height: auto;
  }

  .Title {
    font-size: 200%;
    text-align: center;
  }

  .Navbar {
    margin: 2% 0;
  }

  .Nav-link {
    font-size: 100%;
    margin: 2% 0;
    padding: 2px 10px;
  }

  /* Adjust Images for mobile */
  .Image,
  .Image1-of-2,
  .Image2-of-2 {
    width: 100%;
    margin: 0;
  }

  /* Adjust Texts for mobile */
  .Texts {
    margin: 2% auto;
  }

  .Subscribe-text,
  .address-text {
    font-size: 100%;
    margin-top: 3%;
  }

  /* Mobile adjustments for Primary and Secondary text */
  .Primary-text {
    font-size: 300%;
  }

  .Secondary-text {
    font-size: 150%;
    margin-top: 4%;
  }

  .Secondary-text-2 {
    font-size: 80%;
  }

  /* Mobile adjustments for Text-and-image and Subscribe sections */
  .Text-and-image,
  .Subscribe {
    flex-direction: column;
    text-align: center;
  }

  /* Mobile adjustments for Input-and-button and Text-input */
  .Input-and-button {
    flex-direction: column;
    margin: 5% auto;
    width: 80%;
  }

  .Text-input-background {
    margin-right: 0;
    width: 100%;
  }

  .Text-input {
    margin-top: 2%;
  }

  .Sign-up-button {
    width: 60%;
    margin: 5% auto;
  }

  /* Mobile adjustments for Modal Content/Box */
  .modal-content {
    width: 90%;
    margin: 10% auto;
  }
}

/* Phone view
@media (width <= 500px) {
  .Text-and-image {
    flex-direction: column;
  }
  .Reverse-column-mobile {
    flex-direction: column-reverse;
  }
  .Image {
    margin-bottom: 5%;
    width: 70%;
  }
  .Images {
    margin-bottom: 5%;
    margin-left: 10%;
  }
  .Image1-of-2 {
    width: 40%;
    margin-bottom: 10%;
  }
  .Image2-of-2 {
    width: 40%;
    margin-left: 3%;
  }
  .Navbar {
    /* display: flex; */
    /* flex-direction: column; */
    /* align-items: center; */
    /* margin-top: 3%; */
  /* }
  .Nav-link {
    margin-bottom: 10%;
    margin-left: 10%;
  }
  .Subscribe-text {
    width: 80%;
  }
  .Input-and-button {
    width: 80%;
  }
  .Text-input-background {
    width: 65%;
  }
  .Text-input {
    margin-top: 5%;
  }
  .Sign-up-button {
    width: 35%;
  } */

/* } */

.floating {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
 }
 
 .fab-icon {
  margin-top: 16px;
}